@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Source+Code+Pro:wght@200&display=swap');

.App {
  text-align: center;
}

.App-header {
  background-color: #f0db4f;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 2vmin);
  color: #323330;
}

.title {
  font-family: 'Architects Daughter', cursive;
}

.subtitle {
  font-family: 'Source Code Pro', monospace;;
}